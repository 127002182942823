import { QueryClient } from 'react-query';
import { QueryClient as QueryV5Client } from '@tanstack/react-query';
import isBrowser from '../util/isBrowser';

const guardianQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            enabled: isBrowser,
            // Warning: dismounting and remounting components may bypass no retries
            // setting when response is an error (assumes empty cache and "first" load)
            retry: process.env.NODE_ENV === 'test' ? 0 : 4,
            // Exponential backoff
            retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
        },
    },
});

export const guardianQueryV5Client = new QueryV5Client();

export default guardianQueryClient;
