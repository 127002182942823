/* eslint-disable no-console */
import { create } from 'zustand';
import produce from 'immer';

export const distributionState = create((set) => ({
    clear: () => set(distributionState.getInitialState()),
    data: [],
    loading: false,
    hasErrors: false,
    newAssetType: '',
    setNewAssetType: (newAssetType) =>
        set(() =>
            ({ newAssetType })),
    newAssetSubType: '',
    setNewAssetSubType: (newAssetSubType) =>
        set(() =>
            ({ newAssetSubType })),
    preciousMetalsChildType: '', // for example, if newAssetType is 'Precious MEtals', newAssetSubType is 'State Issued Coins', preciousMetalsChildType is 'Platinum'
    setPreciousMetalsChildType: (preciousMetalsChildType) =>
        set(() =>
            ({ preciousMetalsChildType })),
    showModal: false,
    setShowModal: (showModal) =>
        set(() =>
            ({ showModal })),
    recordType: '',
    setRecordType: (recordType) =>
        set(() =>
            ({ recordType })),
    currentStep: 1,
    setCurrentStep: (step) =>
        set(() =>
            ({ currentStep: step })),
    assets: [],
    setAssets: (assets) =>
        set(() =>
            ({ assets })),
    chosenAsset: {},
    setChosenAsset: (chosenAsset) =>
        set(() =>
            ({ chosenAsset })),
    selectedAssetId: '',
    setSelectedAssetId: (selectedAssetId) =>
        set(() =>
            ({ selectedAssetId })),
    distributionFlowStage1: '',
    setDistributionFlowStage1: (distributionFlowStage1) =>
        set(() =>
            ({ distributionFlowStage1 })),
    transaction: {},
    setTransaction: (transaction) =>
        set(() =>
            ({ transaction })),
    description: '',
    setDescription: (description) =>
        set(() =>
            ({ description })),
    distributionDocumentId: null,
    setDistributionDocumentId: (distributionDocumentId) =>
        set(() =>
            ({ distributionDocumentId })),
    hardshipSignature: '',
    setHardshipSignature: (hardshipSignature) =>
        set(() =>
            ({ hardshipSignature })),

    fetch: async () => {
        set(() =>
            ({ loading: true }));
        try {
            // TODO: API Endpoint Default GET "/transactionNoRecord"
            // TODO Jira bug report: MVP01-321
            throw new Error('API Endpoint Default GET \'/transactionNoRecord\' not implemented');
        } catch (err) {
            set(() => ({
                hasErrors: true,
                loading: false,
            }));
            console.error('From useTransactionStore in appState', err);
        }
    },

    deferralPreTax: {
        enabled: false,
        name: 'Deferral Pre-Tax',
        percent: 0,
        amount: 0,
    },
    setDeferralPreTax: (deferralPreTax) =>
        set(
            produce((draft) => {
                console.log('draft: ', draft.deferralPreTax);
                console.log('incoming from state: ', deferralPreTax);
                // eslint-disable-next-line no-param-reassign
                draft.deferralPreTax = deferralPreTax;
                console.log('new deferralPreTaxValues: ', deferralPreTax);
            }),
        ),
    deferralRoth: {
        enabled: false,
        name: 'Deferral Roth',
        percent: 0,
        amount: 0,
    },
    setDeferralRoth: (deferralRoth) =>
        set(
            produce((draft) => {
                console.log('draft: ', draft.deferralRoth);
                console.log('incoming from state: ', deferralRoth);
                // eslint-disable-next-line no-param-reassign
                draft.deferralRoth = deferralRoth;
                console.log('new deferralRothValues: ', deferralRoth);
            }),
        ),
    profitSharing: {
        enabled: false,
        name: 'Profit Sharing',
        percent: 0,
        amount: 0,
    },
    setProfitSharing: (profitSharing) =>
        set(
            produce((draft) => {
                console.log('draft: ', draft.profitSharing);
                console.log('incoming from state: ', profitSharing);
                // eslint-disable-next-line no-param-reassign
                draft.profitSharing = profitSharing;
                console.log('new profitSharingValues: ', profitSharing);
            }),
        ),
    rollOver: {
        enabled: false,
        name: 'Roll Overs',
        percent: 0,
        amount: 0,
    },
    setRollOver: (rollOver) =>
        set(
            produce((draft) => {
                console.log('draft: ', draft.rollOver);
                console.log('incoming from state: ', rollOver);
                // eslint-disable-next-line no-param-reassign
                draft.rollOver = rollOver;
                console.log('new ROlloverValues: ', rollOver);
            }),
        ),
    afterTax: {
        enabled: false,
        name: 'After Tax',
        percent: 0,
        amount: 0,
    },
    setAfterTax: (afterTax) =>
        set(
            produce((draft) => {
                console.log('afterTax: ', draft.afterTax);
                console.log('incoming from state: ', afterTax);
                // eslint-disable-next-line no-param-reassign
                draft.afterTax = afterTax;
                console.log('new ROlloverValues: ', afterTax);
            }),
        ),
    employerMatch: {
        enabled: false,
        name: 'Employer Match',
        percent: 0,
        amount: 0,
    },
    setEmployerMatch: (employerMatch) =>
        set(
            produce((draft) => {
                console.log('draft: ', draft.employerMatch);
                console.log('incoming from state: ', employerMatch);
                // eslint-disable-next-line no-param-reassign
                draft.employerMatch = employerMatch;
                console.log('new ROlloverValues: ', employerMatch);
            }),
        ),
    onClose: () =>
        set(() => ({
            assetFlowStage1: 'sendingPayment',
            assetPurchasePrice: 0,
            assetTypeLabel: '',
            chosenAsset: {},
            currentStep: 1,
            // data: [],
            hasErrors: false,
            loading: false,
            newAssetSubType: '',
            newAssetType: '',
            preciousMetalsChildType: '', // for example, if newAssetType is 'Precious MEtals', newAssetSubType is 'State Issued Coins'
            recordType: '',
            selectedAssetId: '',
            distributionDocumentId: null,
            showModal: false,
            deferralPreTax: {
                enabled: false,
                name: 'Deferral Pre-Tax',
                percent: 0,
                amount: 0,
            },
            deferralRoth: {
                enabled: false,
                name: 'Deferral Roth',
                percent: 0,
                amount: 0,
            },
            profitSharing: {
                enabled: false,
                name: 'Profit Sharing',
                percent: 0,
                amount: 0,
            },
            rollOver: {
                enabled: false,
                name: 'Roll Overs',
                percent: 0,
                amount: 0,
            },
            afterTax: {
                enabled: false,
                name: 'After Tax',
                percent: 0,
                amount: 0,
            },
            employerMatch: {
                enabled: false,
                name: 'Employer Match',
                percent: 0,
                amount: 0,
            },
        })),
    resetData: () =>
        set(() => ({
            assetFlowStage1: 'sendingPayment',
            assetPurchasePrice: 0,
            assetTypeLabel: '',
            hardship_signature: '',
            chosenAsset: {},
            // currentStep: 1, shouldnt set curretn step to 1 on reset
            // data: [], shouldnt reset data (transactions) on reset only on full close
            hasErrors: false,
            loading: false,
            newAssetSubType: '',
            newAssetType: '',
            preciousMetalsChildType: '', // for example, if newAssetType is 'Precious MEtals', newAssetSubType is 'State Issued Coins'
            recordType: '',
            selectedAssetId: '',
            // showModal: false, shouldn't reset show modal on reset
            deferralPreTax: {
                enabled: false,
                name: 'Deferal Pre-Tax',
                percent: 0,
                amount: 0,
            },
            deferralRoth: {
                enabled: false,
                name: 'Deferal Roth',
                percent: 0,
                amount: 0,
            },
            profitSharing: {
                enabled: false,
                name: 'Profit Sharing',
                percent: 0,
                amount: 0,
            },
            rollOver: {
                enabled: false,
                name: 'Roll Overs',
                percent: 0,
                amount: 0,
            },
            afterTax: {
                enabled: false,
                name: 'After Tax',
                percent: 0,
                amount: 0,
            },
            employerMatch: {
                enabled: false,
                name: 'Employer Match',
                percent: 0,
                amount: 0,
            },
        })),
}));
