/* eslint-disable camelcase */
import { create } from 'zustand';

import { buildTree, breadcrumbsTree } from '@src/util/documents';

const useDocumentsState = create((set) => ({
    // Folders and documents
    clear: () => set(useDocumentsState.getInitialState()),
    treeElements: [],
    selectedFolder: null,
    breadcrumbs: [],
    setData: async ({ folders, documents, versions }) => {
        const treeElements = await buildTree({
            folders, documents, versions, folder_id: null,
        });
        set((state) => {
            const breadcrumbs = breadcrumbsTree(treeElements, state.selectedFolder?.id || null);
            return { treeElements, breadcrumbs };
        });
    },
    setSelectedFolder: (selectedFolder) => {
        set((state) => {
            const breadcrumbs = breadcrumbsTree(
                state.treeElements,
                selectedFolder?.id || null,
            );
            return { selectedFolder, breadcrumbs };
        });
    },
    setBreadcrumbs: (breadcrumbs) => set({ breadcrumbs }),
    // Modals
    uploadModalOpen: false,
    setUploadModalOpen: (uploadModalOpen) => set({ uploadModalOpen }),
    newFolderModalOpen: false,
    setNewFolderModalOpen: (newFolderModalOpen) => set({ newFolderModalOpen }),
    renameModalOpen: false,
    setRenameModalOpen: (renameModalOpen) => set({ renameModalOpen }),
    moveFolderModalOpen: false,
    setMoveFolderModalOpen: (moveFolderModalOpen) => set({ moveFolderModalOpen }),
    uploadNewVersionModal: null,
    setUploadNewVersionModal: (uploadNewVersionModal) => set({ uploadNewVersionModal }),
    moveDocumentModal: null,
    setMoveDocumentModal: (moveDocumentModal) => set({ moveDocumentModal }),
    renameDocumentModal: null,
    setRenameDocumentModal: (renameDocumentModal) => set({ renameDocumentModal }),
    removeDocumentModal: null,
    setRemoveDocumentModal: (removeDocumentModal) => set({ removeDocumentModal }),
}));

export default useDocumentsState;
