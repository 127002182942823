import { create } from 'zustand';

const passwordOptInState = create((set, get) => ({
    clear: () => set(passwordOptInState.getInitialState()),
    showOptInModal: false,
    hasShownOptInModal: false,
    setShowOptInModal: (showOptInModal) => {
        if (get().hasShownOptInModal && showOptInModal) return;
        if (showOptInModal) set({ hasShownOptInModal: true });
        set({ showOptInModal });
    },
}));

export default passwordOptInState;
