import { useState, useEffect } from 'react';
import { useDescope, useSession } from '@descope/react-sdk';
import { useSessionStore } from '@src/store/appState';

// This hook logs out current user when user follows a magic link
const useSessionManager = () => {
    const [isSSR, setIsSSR] = useState(true);
    useEffect(() => {
        setIsSSR(false);
    }, []);

    // useSession automatically refreshes the session token when it is about to expire
    // This is done in the background using the refresh token, without any additional configuration
    // https://github.com/descope/react-sdk
    const { isSessionLoading, sessionToken } = useSession();

    const manageSessions = useSessionStore((state) => state.manageSessions);
    const isTransitioning = useSessionStore((state) => state.isTransitioning());
    const sessionResolverPromise = useSessionStore((state) => state.sessionResolverPromise);
    const initDescope = useSessionStore((state) => state.initDescope);
    const logout = useSessionStore((state) => state.logout);
    const descope = useDescope();
    useEffect(() => { initDescope(descope); }, [/* Keep empty */]);

    // Sometimes Descope's useSession has broken state despite having a valid token. This may occur after magicLink.verify
    // For now we can grab the token straight from storage and later auto-refresh will ensure it works properly
    let token = isSSR ? sessionToken : descope.getSessionToken();
    if (isTransitioning) token = '';

    useEffect(() => {
        if (!isSessionLoading && !sessionResolverPromise) {
            manageSessions({ sessionToken });
        }
    }, [isSessionLoading, sessionResolverPromise]);

    return {
        logout,
        isSessionLoading: isTransitioning || isSessionLoading,
        sessionToken: token,
    };
};

export default useSessionManager;
