import axios from 'axios';
import getConfig from '@src/util/getConfig';
import { getSessionToken } from '@descope/react-sdk';

/**
 * @type {AxiosInstance}
 */
const BACKEND_API = axios.create({
    baseURL: `${getConfig().api_base_url}`,
});

BACKEND_API.interceptors.request.use(
    async (config) => {
        const xanoBranch = getConfig().xano_branch;
        if (!xanoBranch) {
            return config;
        }

        return {
            ...config,
            headers: {
                ...config.headers,
                'X-Branch': xanoBranch,
            },
        };
    },
);

BACKEND_API.interceptors.request.use(
    async (config) => {
        if (config.skipAuth || config.headers.Authorization) {
            return config;
        }
        const accessToken = getSessionToken();

        if (!accessToken) {
            throw new Error('Unable to make secure request, current session does not have a token set.');
        }

        // eslint-disable-next-line no-param-reassign
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${accessToken}`,
        };

        return config;
    },
    (error) => {
        console.error('error: ', error);
        return Promise.reject(error);
    },
);

export {
    BACKEND_API,
};
