import { useDescope } from '@descope/react-sdk';
import { useGetAssumedUserQuery, useGetCurrentUserQuery } from '@src/apiFetch/authApi';
import useSessionManager from '@src/hooks/useSessionManager';
import { useCallback, useContext } from 'react';
import { adminModeState } from '@src/store';
import { BroadcastChannelContext } from '../context/BroadcastChannelContext';
import { stopUnthreadChat } from './useUnthreadChat';

const useUserSession = () => {
    const { logout: logoutDescope, isSessionLoading, sessionToken } = useSessionManager();
    const { getSessionToken } = useDescope();
    const bc = useContext(BroadcastChannelContext);

    // Load /me before anything else to prevent multiple endpoints from trying to provision the user on-demand
    const authUserQuery = useGetCurrentUserQuery({
        enabled: !isSessionLoading && !!sessionToken,
    });
    const assumedUserId = adminModeState((state) => state.id);
    const assumedUserQuery = useGetAssumedUserQuery({ userId: assumedUserId }, { enabled: !!assumedUserId });

    const logout = () => {
        sessionStorage.removeItem('adminMode');
        logoutDescope();
        bc.postMessage({ type: 'logout' });
        setTimeout(() => {
            stopUnthreadChat();
        }, 500);
    };

    const refetch = useCallback(() => {
        authUserQuery.refetch();
        assumedUserQuery.refetch();
    }, [authUserQuery.refetch, assumedUserQuery.refetch]);

    const isRetrievingUser = isSessionLoading || authUserQuery.isLoading || assumedUserQuery.isLoading;
    const currentUser = assumedUserId ? assumedUserQuery.data : authUserQuery.data;
    return {
        isRetrievingUser,
        logout,
        accessToken: sessionToken,
        getAccessToken: getSessionToken,
        authUser: authUserQuery.data,
        user: isRetrievingUser ? null : currentUser,
        refetch,
    };
};

export default useUserSession;
