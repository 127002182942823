/* eslint-disable import/prefer-default-export */
export const PHONE_REGEX = /^(\+1|\d{1,2})?\s*[(]?\d{3}[)]?\s*\d{3}-\d{4}$/;

export const SSN_REGEX = [
    // Regexp explanation: https://ihateregex.io/expr/ssn
    /^(?!0{3})(?!6{3})[0-8]\d{2}-(?!0{2})\d{2}-(?!0{4})\d{4}$/gm,
    // This regex matches the following patterns:

    // A valid SSN, with or without dashes.
    // Any invalid SSN that starts with 000, 666, 900-999, 123-45-6789, 999-99-9999, 078051120, or 219099999.
    // The regex has the following parts:

    // ^(?!000|666|900-999|123-45-6789|999-99-9999|078051120|219099999) : This is a negative lookahead assertion that matches if the string does not start with any of the known invalid SSNs.
    // \d{3} : This matches three digits.
    // - : This matches a hyphen.
    // \d{2} : This matches two digits.
    // - : This matches a hyphen.
    // \d{4} : This matches four digits.
    /^(?!000|666|900-999|123-45-6789|999-99-9999|078051120|219099999)^\d{3}-\d{2}-\d{4}$/,
];

export const EIN_REGEX = /^\d{2}-\d{7}$/;
