import bankStepState from '@src/store/bankingStepState';
import { distributionState } from './distributionState';
import { assetState } from './assetsState';
import documentsState from './documentsState';
import form401kState from './form401kState';
import usePasswordOptInState from './passwordOptInState';

const clearAllState = () => {
    // Intentionally not clearing adminModeState, useCobrowseStore and useSessionStore
    // to prevent circular imports
    assetState.getState().clear();
    bankStepState.getState().clear();
    distributionState.getState().clear();
    documentsState.getState().clear();
    form401kState.getState().clear();
    usePasswordOptInState.getState().clear();
};

export default clearAllState;
