/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
import axios from 'axios';
import { BACKEND_API } from '@src/apiFetch/axiosConfig';

function findIdSelected(obj, selectedId) {
    if (obj.id === selectedId) {
        return true;
    }
    if (obj.children) {
        for (let i = 0; i < obj.children.length; i++) {
            if (findIdSelected(obj.children[i], selectedId)) {
                return true;
            }
        }
    }
    return false;
}

const buildTree = ({
    folders, documents, versions, folder_id = null,
}) => {
    const nestedItems = folders.filter((item) => item.folder_id === folder_id);

    return nestedItems.map((nestedItem) => {
        const { id, name, created_at } = nestedItem;
        const children = buildTree({
            folders, documents, versions, folder_id: id,
        });

        const documentsOnParent = documents.filter((file) => file.folder_id === id);
        const documentsWithVersions = documentsOnParent.map((file) => {
            const fileVersions = versions.filter((version) => version.document_id === file.id);
            return {
                ...file,
                versions: fileVersions,
            };
        });

        return {
            id,
            user_id: nestedItem?.user_id || '',
            name,
            created_at,
            children,
            files: documentsWithVersions,
        };
    });
};

function breadcrumbsTree(tree, selectedId) {
    const result = [];
    const findNode = (node, id) => {
        if (node.id === id) {
            result.push(node);
            return true;
        }
        if (node.children) {
            return node.children.some((child) => {
                if (findNode(child, id)) {
                    result.push(node);
                    return true;
                }
                return false;
            });
        }
        return false;
    };
    tree.some((node) => findNode(node, selectedId));
    return result.reverse();
}

const updateFolders = (folders, id, updater) => folders.map((folder) => {
    if (folder.id === id) {
        return updater(folder);
    }
    return folder;
});

const getGoogleCloudUrl = async (id, accountId, expiration) => {
    const url = await BACKEND_API.get(
        `/documents/${accountId}/download/google-cloud-url`,
        {
            params: {
                id,
                expiration,
            },
        },
    );
    return url.data;
};

const downloadGoogleCloudFile = async ({ name, url }) => {
    const path = url;
    try {
        const response = await axios.get(path, {
            responseType: 'blob',
        });

        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = `${name}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        throw new Error(error);
    }
};

export {
    buildTree,
    breadcrumbsTree,
    findIdSelected,
    updateFolders,
    getGoogleCloudUrl,
    downloadGoogleCloudFile,
};
