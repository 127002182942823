export const stopUnthreadChat = () => {
    if (window && window.$unthread) {
        window.$unthread.inAppChat('stop');
    }
};

export const startUnthreadChat = (name, email) => {
    if (window && window.$unthread) {
        window.$unthread.inAppChat('start', {
            user: {
                name: name || 'Unknown',
                email: email || 'unknown@sepirak.com',
            },
        });
    }
};
