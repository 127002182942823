import React, { useEffect, useState } from 'react';
import { Script } from 'gatsby';
import getConfig from '@src/util/getConfig';
import useUserSession from '@src/hooks/useUserSession';

// https://docs.zipy.ai/product-features/custom-logs
const ZipyProvider = () => {
    const { user: sessionUser } = useUserSession();
    const [isZipyReady, setIsZipyReady] = useState(false);
    const [zipyUser, setZipyUser] = useState();

    useEffect(() => {
        if (!window.zipy) return;
        if (!isZipyReady) return;
        if (zipyUser?.id === sessionUser?.id) return;

        if (!sessionUser?.id && zipyUser) {
            window.zipy.anonymize();
            setZipyUser(null);
            return;
        }

        window.zipy.identify(`${sessionUser.id}`, { email: `${sessionUser.user_email}` });
        setZipyUser(sessionUser);
    }, [isZipyReady, zipyUser?.id, sessionUser?.id]);

    const config = getConfig();
    if (!config.zipy_code || typeof window === 'undefined' || window.zipy !== undefined) {
        return null;
    }
    return (
        <Script
            src="https://cdn.zipy.ai/sdk/v1.0/zipy.min.umd.js"
            strategy="post-hydrate"
            onLoad={() => {
                if (window.zipy) {
                    window.zipy.init(config.zipy_code);
                    setIsZipyReady(true);
                } else {
                    console.error('Unable to load Zipy, missing zipy in window context');
                }
            }}
        />
    );
};

export default ZipyProvider;
