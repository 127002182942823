import guardianQueryClient, { guardianQueryV5Client } from '@src/apiFetch/guardianQueryClient';
import clearAllState from '@src/store/clearAllState';
import { navigate } from 'gatsby';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

// Used for previewing the app as other users
export const adminModeState = create(
    persist(
        (set, get) => ({
            clear: () => set(adminModeState.getInitialState()),
            id: '',
            enabled: false,
            participantName: '',
            from: '',
            toggleAdminMode: ({ id, participantName } = {}) => {
                const from = participantName ? window.location.pathname : '';
                set({
                    id: id || '',
                    enabled: Boolean(participantName),
                    participantName: participantName || '',
                });
                guardianQueryClient.removeQueries({
                    predicate: (query) => {
                        // Do not wipe current user when switching
                        if (
                            query.queryKey[0] === 'getCurrentUser'
                            && query.queryKey[1] === 'me'
                        ) {
                            return false;
                        }
                        // Do not wipe /config
                        if (query.queryKey[0] === 'config') return false;
                        return true;
                    },
                });
                guardianQueryV5Client.removeQueries({
                    predicate: (query) => {
                        // Do not wipe current user when switching
                        if (
                            query.queryKey[0] === 'getCurrentUser'
                            && query.queryKey[1] === 'me'
                        ) {
                            return false;
                        }
                        // Do not wipe /config
                        if (query.queryKey[0] === 'config') return false;
                        return true;
                    },
                });
                clearAllState();
                navigate(get().from || '/');
                set({ from });
            },
        }),
        {
            name: 'adminMode',
            storage: createJSONStorage(() =>
                sessionStorage),
        },
    ),
);
