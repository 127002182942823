import { createContext } from 'react';

export const bc = new BroadcastChannel('default');

/** @type {React.Context<BroadcastChannel>} */
export const BroadcastChannelContext = createContext(bc);

bc.addEventListener('message', (event) => {
    const { data } = event;
    if (data && typeof data === 'object') {
        const { type, payload } = data;
        if (typeof type === 'string') {
            const opts = { detail: payload };
            const event = new CustomEvent(type, opts);
            bc.dispatchEvent(event);
        }
    }
});
