/* eslint-disable no-console */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { create } from 'zustand';
import produce from 'immer';

export const assetState = create((set) => ({
    clear: () => set(assetState.getInitialState()),
    data: [],
    loading: false,
    hasErrors: false,
    assetTypeLabel: '',
    setNewAssetTypeLabel: (assetTypeLabel) =>
        set(() =>
            ({ assetTypeLabel })),
    newAssetType: '',
    setNewAssetType: (newAssetType) =>
        set(() =>
            ({ newAssetType })),
    newAssetSubType: '',
    setNewAssetSubType: (newAssetSubType) =>
        set(() =>
            ({ newAssetSubType })),
    preciousMetalsChildType: '', // for example, if newAssetType is 'Precious MEtals', newAssetSubType is 'State Issued Coins', preciousMetalsChildType is 'Platinum'
    setPreciousMetalsChildType: (preciousMetalsChildType) =>
        set(() =>
            ({ preciousMetalsChildType })),
    showModal: false,
    setShowModal: (showModal) =>
        set(() =>
            ({ showModal })),
    assetsDrawerOpen: false,
    setAssetsDrawerOpen: (assetsDrawerOpen) =>
        set(() =>
            ({ assetsDrawerOpen })),
    recordType: '',
    setRecordType: (recordType) =>
        set(() =>
            ({ recordType })),
    currentStep: 1,
    setCurrentStep: (step) =>
        set((state) =>
            ({ currentStep: (state.currentStep = step) })),
    editingAsset: null,
    setEditingAsset: (editingAsset) =>
        set(() =>
            ({ editingAsset })),
    assets: [],
    setAssets: (assets) =>
        set(() =>
            ({ assets })),
    chosenAsset: {},
    setChosenAsset: (chosenAsset) =>
        set(() =>
            ({ chosenAsset })),
    selectedAssetId: '',
    setSelectedAssetId: (selectedAssetId) =>
        set(() =>
            ({ selectedAssetId })),
    assetFlowStage1: 'sendingPayment',
    setAssetFlowStage1: (AssetFlowStage1) =>
        set(() =>
            ({ AssetFlowStage1 })),
    assetPurchasePrice: 0,
    setAssetPurchasePrice: (assetPurchasePrice) =>
        set(() =>
            ({ assetPurchasePrice })),
    transaction: {},
    setTransaction: (transaction) =>
        set(() =>
            ({ transaction })),
    newlyCreatedAsset: {},
    setNewlyCreatedAsset: (newlyCreatedAsset) =>
        set(() =>
            ({ newlyCreatedAsset })),
    assetCreationUserInput: {},
    setAssetCreationUserInput: (assetCreationUserInput) =>
        set(() =>
            ({ assetCreationUserInput })),

    deferralPreTax: {
        enabled: false,
        name: 'Deferral Pre-Tax',
        percent: 0,
        amount: 0,
    },
    setDeferralPreTax: (deferralPreTax) =>
        set(
            produce((draft) => {
                console.log('incoming from state: ', deferralPreTax);
                draft.deferralPreTax = deferralPreTax;
                console.log('new deferralPreTaxValues: ', deferralPreTax);
            }),
        ),
    deferralRoth: {
        enabled: false,
        name: 'Deferral Roth',
        percent: 0,
        amount: 0,
    },
    setDeferralRoth: (deferralRoth) =>
        set(
            produce((draft) => {
                console.log('draft: ', draft.deferralRoth);
                console.log('incoming from state: ', deferralRoth);
                draft.deferralRoth = deferralRoth;
                console.log('new deferralRothValues: ', deferralRoth);
            }),
        ),
    profitSharing: {
        enabled: false,
        name: 'Profit Sharing',
        percent: 0,
        amount: 0,
    },
    setProfitSharing: (profitSharing) =>
        set(
            produce((draft) => {
                console.log('draft: ', draft.profitSharing);
                console.log('incoming from state: ', profitSharing);
                draft.profitSharing = profitSharing;
                console.log('new profitSharingValues: ', profitSharing);
            }),
        ),
    rollOver: {
        enabled: false,
        name: 'Roll Overs',
        percent: 0,
        amount: 0,
    },
    setRollOver: (rollOver) =>
        set(
            produce((draft) => {
                console.log('draft: ', draft.rollOver);
                console.log('incoming from state: ', rollOver);
                draft.rollOver = rollOver;
                console.log('new ROlloverValues: ', rollOver);
            }),
        ),

    afterTax: {
        enabled: false,
        name: 'After Tax',
        percent: 0,
        amount: 0,
    },
    setAfterTax: (afterTax) =>
        set(
            produce((draft) => {
                console.log('afterTax: ', draft.afterTax);
                console.log('incoming from state: ', afterTax);
                draft.afterTax = afterTax;
                console.log('new ROlloverValues: ', afterTax);
            }),
        ),
    employerMatch: {
        enabled: false,
        name: 'Employer Match',
        percent: 0,
        amount: 0,
    },
    setEmployerMatch: (employerMatch) =>
        set(
            produce((draft) => {
                console.log('draft: ', draft.employerMatch);
                console.log('incoming from state: ', employerMatch);
                draft.employerMatch = employerMatch;
                console.log('new ROlloverValues: ', employerMatch);
            }),
        ),

    fetch: async () => {
        set(() =>
            ({ loading: true }));
        try {
            console.log('fetching transactions assetState');
            // TODO: API Endpoint Default GET "/transactionNoRecord"
            // TODO Jira bug report: MVP01-321
            throw new Error('API Endpoint Default GET \'/transactionNoRecord\' not implemented');
        } catch (err) {
            set(() => ({
                hasErrors: true,
                loading: false,
            }));
            console.error('From useTransactionStore in appState', err);
        }
    },

    onClose: () =>
        set(() => ({
            assetFlowStage1: 'sendingPayment',
            assetPurchasePrice: 0,
            assetTypeLabel: '',
            chosenAsset: {},
            currentStep: 1,
            // data: [],
            hasErrors: false,
            loading: false,
            newAssetSubType: '',
            newAssetType: '',
            preciousMetalsChildType: '', // for example, if newAssetType is 'Precious MEtals', newAssetSubType is 'State Issued Coins'
            recordType: '',
            selectedAssetId: '',
            showModal: false,
            editingAsset: null,
            deferralPreTax: {
                enabled: false,
                name: 'Deferral Pre-Tax',
                percent: 0,
                amount: 0,
            },
            deferralRoth: {
                enabled: false,
                name: 'Deferral Roth',
                percent: 0,
                amount: 0,
            },
            profitSharing: {
                enabled: false,
                name: 'Profit Sharing',
                percent: 0,
                amount: 0,
            },
            rollOver: {
                enabled: false,
                name: 'Roll Overs',
                percent: 0,
                amount: 0,
            },
            afterTax: {
                enabled: false,
                name: 'After Tax',
                percent: 0,
                amount: 0,
            },
            employerMatch: {
                enabled: false,
                name: 'Employer Match',
                percent: 0,
                amount: 0,
            },
        })),
    onCloseEdit: () =>
        set(() => ({
            assetsDrawerOpen: true,
            showModal: false,
        })),
    onCloseDrawer: () =>
        set(() => ({
            assetsDrawerOpen: false,
            showModal: false,
            currentStep: 1,
        })),
    resetData: () =>
        set(() => ({
            assetFlowStage1: 'sendingPayment',
            assetPurchasePrice: 0,
            assetTypeLabel: '',
            chosenAsset: {},
            // currentStep: 1, shouldnt set curretn step to 1 on reset
            // data: [], shouldnt reset data (transactions) on reset only on full close
            hasErrors: false,
            loading: false,
            editingAsset: null,
            newAssetSubType: '',
            newAssetType: '',
            preciousMetalsChildType: '', // for example, if newAssetType is 'Precious MEtals', newAssetSubType is 'State Issued Coins'
            recordType: '',
            selectedAssetId: '',
            // showModal: false, shouldn't reset show modal on reset
            deferralPreTax: {
                enabled: false,
                name: 'Deferral Pre-Tax',
                percent: 0,
                amount: 0,
            },
            deferralRoth: {
                enabled: false,
                name: 'Deferral Roth',
                percent: 0,
                amount: 0,
            },
            profitSharing: {
                enabled: false,
                name: 'Profit Sharing',
                percent: 0,
                amount: 0,
            },
            rollOver: {
                enabled: false,
                name: 'Roll Overs',
                percent: 0,
                amount: 0,
            },
            afterTax: {
                enabled: false,
                name: 'After Tax',
                percent: 0,
                amount: 0,
            },
            employerMatch: {
                enabled: false,
                name: 'Employer Match',
                percent: 0,
                amount: 0,
            },
        })),
}));
