import React, { ProviderProps, ReactNode } from 'react';
import { QueryClientProvider } from 'react-query';
import { QueryClientProvider as QueryV5ClientProvider } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import { Toaster } from 'react-hot-toast';
import guardianQueryClient, { guardianQueryV5Client } from '@src/apiFetch/guardianQueryClient';
import { AuthProvider } from '@descope/react-sdk';
import getConfig from '@src/util/getConfig';
import ZipyProvider from '@src/components/thirdPartyProviders/Zipy/ZipyProvider';
import SentryProvider from '@src/components/thirdPartyProviders/Sentry/SentryProvider';
import {
    BroadcastChannelContext,
    bc,
} from '@src/context/BroadcastChannelContext';

type GlobalProviderProps = {
    // eslint-disable-next-line react/no-unused-prop-types
    isSSR: boolean;
    children: ReactNode | JSX.Element;
};

const EmptyWrapper = ({ children }: any) => {
    return children;
};

export const RootProvider = ({ isSSR, children }: GlobalProviderProps) => {
    const config = getConfig();
    const BroadcastProvider: React.FC<ProviderProps<BroadcastChannel>> = isSSR
        ? EmptyWrapper
        : BroadcastChannelContext.Provider;
    return (
        // eslint-disable-next-line react/jsx-filename-extension
        <BroadcastProvider value={bc}>
            <QueryClientProvider client={guardianQueryClient}>
                <QueryV5ClientProvider client={guardianQueryV5Client}>
                    <Helmet
                        link={[
                            {
                                rel: 'icon',
                                type: 'image/svg',
                                href: config.favicon_url,
                            },
                        ]}
                    >
                        <script
                            async
                            src={`https://www.googletagmanager.com/gtag/js?id=${config.google_analytics_id}`}
                        />
                        <script>
                            {` window.dataLayer = window.dataLayer || []
                                function gtag() {
                                    dataLayer.push(arguments)
                                }
                                gtag('js', new Date())
                                gtag('config', '${config.google_analytics_id}')`}
                        </script>
                        {/* Unthread Embed Script */}
                        <script>
                            {`(function (w, d, t) {
                                w.$unthreadInAppChatSettings = {
                                    baseUrl: 'https://sepirak.unthread.io',
                                    widgetId: '${config.unthread_chat_id}'
                                };

                                var u = function() {
                                    u.c(arguments);
                                };
                                u.q = [];
                                u.c = function(args) {
                                    u.q.push(args);
                                };

                                if (!w.$unthread) {
                                    w.$unthread = {};
                                }

                                w.$unthread.inAppChat = u;

                                var g = d.createElement(t), s = d.getElementsByTagName(t)[0];
                                g.src = w.$unthreadInAppChatSettings.baseUrl + '/widget/js/wrapper.js';
                                g.defer = true;
                                g.async = true;
                                s.parentNode.insertBefore(g, s);
                            })(window, document, 'script');`}
                        </script>
                        {/* PostHog Embed Script */}
                        <script>
                            {`
                                !function(t, e) {
                                    var o, n, p, r;
                                    e.__SV || (window.posthog = e, e._i = [], e.init = function(i, s, a) {
                                        function g(t, e) {
                                            var o = e.split(".");
                                            2 == o.length && (t = t[o[0]], e = o[1]),
                                            t[e] = function() {
                                                t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
                                            }
                                        }
                                        (p = t.createElement("script")).type = "text/javascript",
                                        p.async = !0,
                                        p.src = s.api_host.replace(".i.posthog.com", "-assets.i.posthog.com") + "/static/array.js",
                                        (r = t.getElementsByTagName("script")[0]).parentNode.insertBefore(p, r);
                                        var u = e;
                                        void 0 !== a ? u = e[a] = [] : a = "posthog",
                                        u.people = u.people || [],
                                        u.toString = function(t) {
                                            var e = "posthog";
                                            return "posthog" !== a && (e += "." + a),
                                            t || (e += " (stub)"),
                                            e;
                                        },
                                        u.people.toString = function() {
                                            return u.toString(1) + ".people (stub)";
                                        },
                                        o = "capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags getFeatureFlag getFeatureFlagPayload reloadFeatureFlags group updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures getActiveMatchingSurveys getSurveys getNextSurveyStep onSessionId setPersonProperties".split(" "),
                                        n = 0;
                                        for (n = 0; n < o.length; n++) g(u, o[n]);
                                        e._i.push([i, s, a]);
                                    }, e.__SV = 1);
                                }(document, window.posthog || []);
                                if (!window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost') && !window.location.host.includes('dev.guardianselfdirection.com') && Boolean(${config.posthog_key})) {
                                    posthog.init('${config.posthog_key}', {
                                        api_host: '${config.posthog_host}',
                                        person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
                                    });
                                }
                            `}
                        </script>
                    </Helmet>
                    {children}
                </QueryV5ClientProvider>
            </QueryClientProvider>
        </BroadcastProvider>
    );
};

export const PageProvider = ({ children }: GlobalProviderProps) => {
    const config = getConfig();
    return (
        <>
            {/* @ts-ignore */}
            <AuthProvider projectId={config.descope_project_id}>
                <ZipyProvider />
                <SentryProvider />
                {children}
            </AuthProvider>
            <Toaster toastOptions={{ duration: 5000 }} />
        </>
    );
};
