/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import './src/styles/global.css';
import './src/styles/custom.css';
import './src/styles/blobz.min.css';
import React, { ReactNode } from 'react';
import { RootProvider, PageProvider } from '@src/common/ui/Providers';

export const wrapRootElement = ({ element }: { element: ReactNode }) => (
    <RootProvider isSSR={false}>
        {element}
    </RootProvider>
);

export const wrapPageElement = ({ element }: { element: ReactNode }) => (
    <PageProvider isSSR={false}>
        {element}
    </PageProvider>
);

// Prevent page scrolling on url search parameters change
export const shouldUpdateScroll = () => {
    return false;
};
