/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { create } from 'zustand';
import queryString from 'query-string';
import { navigate } from '@gatsbyjs/reach-router';
import guardianQueryClient, { guardianQueryV5Client } from '@src/apiFetch/guardianQueryClient';
import getConfig from '@src/util/getConfig';
import clearAllState from '@src/store/clearAllState';
import { adminModeState } from '@src/store/adminModeState';

const withStorageDOMEvents = (store) => {
    const storageEventCallback = (e) => {
        if (e.key === store.persist.getOptions().name && e.newValue) {
            console.log('Rehydrating!');
            store.persist.rehydrate();
        }
    };

    window.addEventListener('storage', storageEventCallback);

    return () => {
        window.removeEventListener('storage', storageEventCallback);
    };
};

export const useCobrowseStore = create(
    (set) => ({
        clear: () => set(useCobrowseStore.getInitialState()),
        initialized: false,
        session: null,
        cobrowseIO: null,
        setSession: (session) => set({ session }),
        setInitialized: (initialized) => set({ initialized }),
        setCobrowseIO: (cobrowseIO) => set({ cobrowseIO }),
    }),
);

export const useSessionStore = create(
    (set, get) => ({
        clear: () => set(useSessionStore.getInitialState()),
        sdk: null,
        logoutPromise: null,
        descope: null,
        initDescope: (descope) => {
            if (!get().descope) set({ descope });
        },
        isLoggingOut: false,
        logout: async ({ redirect = true } = {}) => {
            const state = get();
            if (state.logoutPromise) return;
            const logoutPromise = state.descope.logout();
            set({ logoutPromise, isLoggingOut: true });
            await logoutPromise;
            guardianQueryClient.removeQueries();
            guardianQueryV5Client.removeQueries();
            if (redirect) navigate('/');
            set({ logoutPromise: null, isLoggingOut: false });
            adminModeState.getState().clear();
            clearAllState();
        },
        sessionResolverPromise: null,
        isResolving: true,
        manageSessions: async ({ sessionToken }) => {
            const state = get();
            if (state.sessionResolverPromise) return;
            const sessionResolverPromise = (async () => {
                const qs = queryString.parse(window.location.search);
                const loginOnLanding = qs.t;
                if (!loginOnLanding) return;
                const isInviteLink = loginOnLanding && !window.location.search.includes('flow');
                if (loginOnLanding) {
                    if (sessionToken) {
                        await state.logout({ redirect: false });
                    }
                    if (isInviteLink) {
                        await state.descope.magicLink.verify(qs.t);
                        delete qs.t;
                        navigate(`${window.location.pathname}${queryString.stringify(qs)}`);
                    }
                }
            })();
            if (!state.sessionResolverPromise) set({ isResolving: true, sessionResolverPromise });
            await sessionResolverPromise;
            set({ isResolving: false });
        },
        isTransitioning: () => {
            const { isResolving, isLoggingOut } = get();
            return isResolving || isLoggingOut;
        },
    }),
);
